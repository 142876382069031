import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/img/team/1.png";
const teamThreeData = [
  {
    id: uuidv4(),
    img: "/images/ee.jpg",
    title: "Electrical Engineer",
    location: "Karachi,Pakistan",
    fb: "#",
    twitter: "#",
    insta: "#",
    linkedIn: "#",
  },
  {
    id: uuidv4(),
    img: "/images/autospecialist.jpg",
    title: "Automation Specialist",
    location: "Karachi,Pakistan",
    fb: "#",
    twitter: "#",
    insta: "#",
    linkedIn: "#",
  },
  {
    id: uuidv4(),
    img: "/images/electrician.jpg",
    title: "Industrial Electrician",
    location: "Karachi,Pakistan",
    fb: "#",
    twitter: "#",
    insta: "#",
    linkedIn: "#",
  },
  {
    id: uuidv4(),
    img: "/images/techni.jpg",
    title: "Maintenance Technician",
    location: "Karachi,Pakistan",
    fb: "#",
    twitter: "#",
    insta: "#",
    linkedIn: "#",
  },
  {
    id: uuidv4(),
    img: "/images/pm.jpg",
    title: "Project Manager - Automation",
    location: "Karachi,Pakistan",
    fb: "#",
    twitter: "#",
    insta: "#",
    linkedIn: "#",
  },
  {
    id: uuidv4(),
    img: "/images/sys eng.jpeg",
    title: "Control Systems Engineer",
    location: "Karachi,Pakistan",
    fb: "#",
    twitter: "#",
    insta: "#",
    linkedIn: "#",
  },
];

export default teamThreeData;
