import React from "react";
import HeaderOne from "../Components/Header/HeaderOne";
import PageBanner from "../Components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import FooterTwo from "../Components/Footer/FooterTwo";
import NewsWrapper from "../Components/News";

const ProjectsPage = () => {
  return (
    <>
      <PageBanner
        title="Our Projects"
        bannerBg="/images/projectsbg.jpg"
        currentPage="Projects"
      />
      <NewsWrapper />
      <FooterTwo />
    </>
  );
};

export default ProjectsPage;
