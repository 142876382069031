import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

const images = [
  "/images/bg-hero2.jpg", // Replace with your image paths
  "/images/bg-hero3.jpg",
  "/images/bg-hero1.jpg",
];
const slides = [
  {
    image: "/images/bg-hero3.jpg",
    heading: "Powering the Taste of a Smarter Life",
    tagline:
      "  Electrical and automation solutions that bring life to your home and business",
  },
  {
    image: "/images/bg-hero2.jpg",
    heading: "Bringing Life to Your Vision",
    tagline: "Expertise in electrical and automation solutions",
  },
  //   {
  //     image: "/images/bg-hero1.jpg",
  //     heading: "Bringing Life to Your Vision",
  //     tagline: "Expertise in electrical and automation solutions",
  //   },
];

const HeroOne = () => {
  return (
    <section className="hero-wrapper hero-1">
      <Swiper navigation={true} modules={[Navigation]} loop>
        {slides.length > 0 &&
          slides.map((slide, index) => (
            <SwiperSlide
              key={index}
              className="bg-cover"
              style={{
                backgroundImage: `url(${slide.image})`,
                position: "relative",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* Overlay */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay with 50% opacity
                  zIndex: 1, // Ensure the overlay is above the background
                }}
              ></div>

              {/* Content */}
              <div
                className="container"
                style={{ position: "relative", zIndex: 2 }}
              >
                <div className="row">
                  <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                    <div className="hero-contents pe-lg-3">
                      <h1
                        // style={{ width: "100%" }}
                        className="fs-lg wow fadeInLeft animated"
                        data-wow-duration="1.3s"
                      >
                        {slide.heading}
                      </h1>
                      <p
                        className="pe-lg-5 wow fadeInLeft animated"
                        data-wow-duration="1.3s"
                        data-wow-delay=".4s"
                      >
                        {slide.tagline}
                      </p>
                      <Link
                        to="/contact"
                        className="theme-btn me-sm-4 wow fadeInLeft"
                      >
                        contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  );
};

export default HeroOne;
