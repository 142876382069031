import React from "react";
import { Link } from "react-router-dom";

const MobileMenu = ({ mobileMenu, handleMobileMenu, setMobileMenu }) => {
  return (
    <div className={mobileMenu ? "mobile-nav" : "mobile-nav show"}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          <li>
            <Link onClick={handleMobileMenu} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={handleMobileMenu} to="/services">
              Services
            </Link>
          </li>

          <li>
            <Link onClick={handleMobileMenu} to="/projects">
              Projects
            </Link>
          </li>

          <li>
            <Link onClick={handleMobileMenu} to="/careers">
              Careers
            </Link>
          </li>
          <li>
            <Link onClick={handleMobileMenu} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <div className="action-bar">
        <a style={{ textTransform: "none" }} href="mailto:hr@taste-oflife.com">
          <i className="fal fa-envelope"></i>hr@taste-oflife.com
        </a>
        <a href="tel:123-456-7890">
          <i className="fal fa-phone"></i>03332398142
        </a>
        <Link
          onClick={handleMobileMenu}
          to="/contact"
          className="d-btn theme-btn"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
