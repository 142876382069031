const HeroSocials = () => {
  return (
    <>
      <div className="hero-social-elements d-none d-xxl-block">
        <div className="flp-text">
          <p>Follow Us</p>
        </div>
        <div className="long-arrow"></div>
        <div className="social-link">
          <a
            href="https://www.facebook.com/profile.php?id=100067875286974"
            target="_blank"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          {/* <a href="#">
            <i className="fab fa-twitter"></i>
          </a> */}
          <a
            href="https://www.youtube.com/@eatelectricalautomationtra1069"
            target="_blank"
          >
            <i className="fab fa-youtube"></i>
          </a>
          {/* <a href="#">
            <i className="fab fa-linkedin-in"></i>
          </a> */}
        </div>
      </div>
    </>
  );
};

export default HeroSocials;
