import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/img/client/1.jpg";

const TestimonialTwoData = [
  {
    id: uuidv4(),
    img: img1,
    name: "Alex Johnson",
    designation: "Operations Manager, Global Industries",
    speech:
      "Taste of Life delivered exceptional automation services for our production line. Their expertise helped streamline our processes, significantly improving efficiency. Highly recommended for any industrial automation needs!",
  },
  {
    id: uuidv4(),
    img: img1,
    name: "Linda Williams",
    designation: "CEO, TradeLink Exports",
    speech:
      "I trust Taste of Life for all our import and export needs. They provide reliable and efficient services, ensuring that our products reach their destination on time and in perfect condition.",
  },
  {
    id: uuidv4(),
    img: img1,
    name: "David Smith",
    designation: "Facility Manager, Tech Solutions",
    speech:
      "The electrical services from Taste of Life are top-notch. They upgraded our entire facility’s electrical systems, ensuring safety and compliance. Their team is professional, knowledgeable, and efficient.",
  },
];

export default TestimonialTwoData;
