import { v4 as uuidv4 } from "uuid";
import bg1 from "../../assets/img/services/1.jpg";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/irrigation-system.png",
    title: "Irrigation Systems",
    desc: "Optimize and configure irrigation systems for effective water management. Our solutions ensure efficient resource use in agricultural practices.",
  },

  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/automationicon.png",
    title: "Automation Solutions",
    desc: "Enhance operational efficiency through advanced automation solutions. We provide customized PLC programming and system integration services.",
  },

  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/plug.png",
    title: "Electrical Services and Safety",
    desc: "Ensure safety and compliance through comprehensive electrical testing services. We utilize advanced equipment for accurate earth testing.",
  },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/free-trade.png",
    title: "Import and Export Services",
    desc: "Facilitating the global trade of specialized equipment and technologies, ensuring timely and compliant delivery to enhance your business operations.",
  },

  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/vfd.png",
    title: "Variable Frequency Drives (VFD)",
    desc: "Program and configure VFDs to optimize motor control and energy efficiency. Our expertise minimizes operational costs and enhances system performance.",
  },

  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/protection.png",
    title: "Dry Run Protection Systems",
    desc: "Implement protective measures to prevent equipment failures due to dry running conditions. Our solutions enhance equipment longevity and reliability.",
  },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/no-sound.png",
    title: "Noise Reduction Solutions",
    desc: "Implement effective solutions for noise reduction in industrial systems. Our strategies create a safer and more comfortable working environment.",
  },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "/images/plasma-membrane.png",
    title: "Membrane Bioreactor (MBR) Installation",
    desc: "Specialize in the installation of MBR systems for efficient sewage treatment. We ensure optimal performance through expert setup and configuration.",
  },
];

export default servicesOneData;
