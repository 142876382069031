import React, { useState } from "react";
import { Link } from "react-router-dom";
import post1 from "../../assets/img/blog/p1.jpg";
import ModalVideo from "react-modal-video";

const NewsWrapper = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState("");
  const [expandedProjectIndex, setExpandedProjectIndex] = useState(null); // State for expanded project index

  const projects = [
    {
      videoUrl: "https://www.youtube.com/watch?v=ZAznrKawZTE",
      heading: "ESP-RZXE Configuration",
      category: "Configuration",
      para: "Master the ESP-RZXE configuration process to optimize irrigation systems and ensure efficient water management. This video covers step-by-step procedures and tips for effective irrigation system management, including how to configure water schedules, adjust settings for different soil types, and utilize weather-based watering to save water. It dives deep into the various settings available in the ESP-RZXE controller and how they can be adjusted for different scenarios, such as residential gardens, commercial landscapes, and agricultural applications. Additionally, you'll find real-world examples of successful implementations that demonstrate the impact of proper configuration on reducing water waste and improving plant health.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=v3OyvXBzFUQ",
      heading: "Unitronics VisiLogic PLC Introduction",
      category: "Automation",
      para: "Get introduced to the Unitronics VisiLogic PLC, understanding its programming environment and capabilities. This tutorial provides a comprehensive overview of its features and functionalities for automating processes, including visual programming, HMI integration, and communication protocols. The video also covers the essential components of the VisiLogic software, such as project organization, ladder diagram programming, and function blocks. It includes common programming pitfalls and how to avoid them, making it a perfect starting point for beginners and a useful reference for experienced users. Throughout the tutorial, practical examples illustrate how to implement automation solutions in various industrial scenarios, enhancing productivity and efficiency.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=G6xJ-vJZ5jY",
      heading: "Limit Switch Used for Protection",
      category: "Protection",
      para: "Learn about limit switches and their crucial role in providing protection and safety in electrical systems. This video explains different types of limit switches, including mechanical, proximity, and photoelectric types, and their applications in various industries such as manufacturing, robotics, and safety systems. It includes in-depth discussions on installation best practices, wiring techniques, and maintenance tips to ensure long-term reliability and safety. The tutorial also covers troubleshooting common issues associated with limit switches and highlights the importance of selecting the right switch for specific applications to prevent equipment failures and enhance operational safety.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=vlw1ZVsP_is",
      heading: "Grit & Grease Removal Bridge TAIF",
      category: "Wastewater Treatment",
      para: "Explore the Grit & Grease Removal Bridge at TAIF and its importance in maintaining wastewater treatment efficiency. The video demonstrates the technology used, such as automated screens and conveyor systems, and its impact on environmental protection. It details how the removal of grit and grease prevents damage to pumps and equipment, ensuring optimal flow rates and treatment processes. The tutorial also highlights case studies showcasing how this system enhances the community's ability to manage wastewater effectively, contributing to overall public health and environmental sustainability.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=AYOcSh5VydY",
      heading: "Altivar ATV660 Schneider PHASE ROTATION AND NOISE REDUCTION",
      category: "VFD Settings",
      para: "Understand phase rotation and noise reduction techniques for the Altivar ATV660 Schneider VFD to enhance performance. This tutorial guides you through practical adjustments, including phase balancing and implementing filters to reduce electrical noise, which can impact motor performance and lifespan. The video discusses scenarios where these adjustments can lead to significant efficiency gains, such as improved torque delivery and reduced energy consumption in various applications. Additionally, the tutorial provides insights into troubleshooting common issues related to phase rotation and noise interference in VFD systems, ensuring that users can achieve optimal operational control.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=w4ssOvJEsIA",
      heading: "Altivar ATV660 Schneider VFD PARAMETER PROGRAMMING SETTING",
      category: "VFD Programming",
      para: "Learn how to program and set parameters for the Altivar ATV660 Schneider VFD for optimal operational control. The video covers common mistakes and troubleshooting tips, providing insights on how to tweak settings for improved performance in various applications, such as pumps, fans, and conveyors. Specific parameter adjustments, such as acceleration/deceleration rates, torque limits, and PID control settings, are discussed in detail. Additionally, viewers will gain an understanding of how to integrate the VFD with other control systems, including PLCs and HMIs, to create a cohesive automation solution that maximizes efficiency and productivity.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=rt-uOip6p0U",
      heading: "KUBOTA MEMBRANE MBR INSTALLATION STP",
      category: "Installation",
      para: "A comprehensive guide to the installation of KUBOTA membranes in Membrane Bioreactor (MBR) systems at sewage treatment plants. This video provides detailed installation steps, including pre-installation preparation, membrane module assembly, and connection to the bioreactor. Maintenance guidelines are also covered, ensuring that viewers understand both the technical and practical aspects of installation for effective operation. The tutorial highlights the advantages of using KUBOTA membranes, such as high filtration efficiency and durability, and discusses troubleshooting common installation issues to ensure a successful setup.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=OhPFQJ48J9E",
      heading: "FMR10 and FMR20 Radar Level Transmitter with RIA 15",
      category: "Level Measurement",
      para: "Discover the features and applications of FMR10 and FMR20 radar level transmitters with RIA 15 for precise level measurement. The video demonstrates calibration procedures and integration with control systems, discussing the importance of accurate level measurement in industrial processes, including tanks, silos, and open channels. Detailed explanations of the radar technology used in these transmitters provide insights into how they operate in challenging environments, including extreme temperatures and pressures. Additionally, the tutorial addresses common challenges in level measurement and offers solutions to enhance measurement accuracy and reliability.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=PqJmYiRRcXE",
      heading: "EARTH TESTING With Kyoritsu Digital Earth Tester",
      category: "Testing",
      para: "Learn the essential techniques for earth testing using the Kyoritsu Digital Earth Tester to ensure safety and compliance. The video highlights important testing methods, including earth resistance measurements and testing electrode systems, along with safety precautions and best practices for conducting tests in various environments. Viewers will gain insights into interpreting test results and understanding the implications for electrical system safety and performance. The tutorial also discusses regulatory compliance issues and provides guidance on how to document testing results effectively.",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=cF6Y6_oyftA",
      heading: "Altivar ATV660 Schneider DRY RUN PROTECTION BY VFD",
      category: "VFD Protection",
      para: "Understand how to implement dry run protection using the Altivar ATV660 Schneider VFD to prevent system failures. The video discusses various protection strategies, including configuring sensors and alarms to detect low flow conditions and their importance in maintaining system integrity. Real-world case studies demonstrate successful implementations of dry run protection, showcasing how these measures prevent damage to pumps and associated equipment. Additionally, viewers will learn about integrating protection features into existing systems, enhancing operational reliability and minimizing downtime.",
    },
  ];

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate total pages
  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const currentProjects = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle video modal
  const openModal = (videoId) => {
    setCurrentVideoId(videoId);
    setOpen(true);
  };

  // Toggle expand/collapse of project description
  const toggleExpand = (index) => {
    setExpandedProjectIndex(expandedProjectIndex === index ? null : index);
  };

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={currentVideoId.split("v=")[1]} // Extract video ID from URL
        onClose={() => setOpen(false)}
      />
      <section className="blog-wrapper news-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="blog-posts">
                {currentProjects.map((project, index) => (
                  <div
                    key={index}
                    className="single-blog-post format-video video-post"
                  >
                    <div
                      className="post-featured-thumb bg-cover"
                      style={{
                        backgroundImage: `url("/images/vid-bg.png")`,
                        backgroundPosition: "top",
                      }}
                    >
                      <div className="video-play-btn">
                        <span
                          style={{ cursor: "pointer" }}
                          className="play-video popup-video"
                          onClick={() => openModal(project.videoUrl)}
                        >
                          <i className="fas fa-play"></i>
                        </span>
                      </div>
                    </div>
                    <div className="post-content">
                      <div className="post-cat">
                        <Link to="" style={{ cursor: "default" }}>
                          {project.category}
                        </Link>
                      </div>
                      <h2>{project.heading}</h2>
                      <p>
                        {expandedProjectIndex === index
                          ? project.para
                          : `${project.para.substring(0, 200)}...`}
                        <span
                          className="see-more"
                          style={{
                            cursor: "pointer",
                            color: "blue", // Optional: Change color to indicate it's clickable
                            // textDecoration: "underline", // Optional: Underline to indicate it's a link
                          }}
                          onClick={() => toggleExpand(index)}
                        >
                          {expandedProjectIndex === index
                            ? " See Less"
                            : " See More"}
                        </span>
                      </p>
                      <div className="d-flex justify-content-between align-items-center mt-30"></div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="page-nav-wrap mt-60 text-center">
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        currentPage > 1 && setCurrentPage(currentPage - 1)
                      }
                    >
                      <i className="fal fa-long-arrow-left"></i>
                    </Link>
                  </li>
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li key={index}>
                        <Link
                          to=""
                          onClick={() => setCurrentPage(pageNumber)}
                          style={{
                            backgroundColor:
                              currentPage === pageNumber
                                ? "#FF5E14"
                                : "transparent",
                            color:
                              currentPage === pageNumber
                                ? "#FFFFFF"
                                : "inherit",
                          }}
                        >
                          {pageNumber}
                        </Link>
                      </li>
                    );
                  })}
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        currentPage < totalPages &&
                        setCurrentPage(currentPage + 1)
                      }
                    >
                      <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsWrapper;
