import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import mainLogo from "../../assets/img/logo.png";
import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";

const HeaderOne = () => {
  const [search, setSearch] = useState(true);
  const [offset, setOffset] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);
  const location = useLocation();
  console.log("the pathname is", location.pathname);
  const links = [
    { name: "Home", path: "/" },
    { name: "Services", path: "/services" },
    { name: "Projects", path: "/projects" },
    { name: "Careers", path: "/careers" },
    { name: "Contact", path: "/contact" },
  ];

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  useEffect(() => {
    console.log("The pathname is", location.pathname); // This will log every time the pathname changes
  }, [location]);
  return (
    <>
      <OffsetMenu offset={offset} handleOffset={handleOffset} />
      <header className="header-wrap header-1">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="logo">
            <Link to="/">
              <img
                style={{
                  // width: "100px",
                  marginLeft: 10,
                  height: "70px",
                  objectFit: "cover",
                }}
                src="/images/logo-white.png"
                // src="/images/logo-black.jpeg"
                alt="logo"
              />
            </Link>
          </div>
          <div className="header-right-area d-flex">
            <div className="main-menu d-none d-xl-block">
              <ul>
                {links.map((link) => (
                  <li key={link.path}>
                    <Link
                      to={link.path}
                      style={{
                        textDecoration:
                          location.pathname === link.path
                            ? "underline"
                            : "none",
                        textUnderlineOffset:
                          location.pathname === link.path && 10,
                        textDecorationThickness:
                          location.pathname === link.path && 4,
                        textDecorationColor:
                          location.pathname === link.path && "#ff5e14",
                      }}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}

                {/* <li>
                  <a href="#" onClick={handleSearch} className="search-btn">
                    <i className="fas fa-search"></i>
                  </a>
                  <div className={search ? "search-box" : "search-box show"}>
                    <form action="#">
                      <input type="text" placeholder="Search" />
                      <button type="submit">
                        <i className="fal fa-search"></i>
                      </button>
                    </form>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className="header-right-elements d-flex align-items-center justify-content-between">
              <Link to="/contact" className="theme-btn d-none d-sm-block">
                Get Free Quote
              </Link>
              <span
                onClick={handleOffset}
                className="side-menu-toggle d-none d-xl-block"
              >
                <i className="fal fa-bars"></i>
              </span>
              <div className="d-inline-block ms-4 d-xl-none">
                <div className="mobile-nav-wrap">
                  <div id="hamburger" onClick={handleMobileMenu}>
                    <i className="fal fa-bars"></i>
                  </div>
                  <MobileMenu
                    mobileMenu={mobileMenu}
                    handleMobileMenu={handleMobileMenu}
                    setMobileMenu={setMobileMenu}
                  />
                </div>
                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderOne;
