import React from "react";
import { Link } from "react-router-dom";

const PageBanner = ({ bannerBg, currentPage, title }) => {
  return (
    <div
      className="page-banner-wrap bg-cover"
      style={{
        backgroundImage: `url(${bannerBg})`,
        position: "relative",
        zIndex: 4,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.45)", // Black overlay with 50% opacity
          zIndex: 1, // Ensure the overlay is above the background
        }}
      ></div>
      <div className="container" style={{ position: "relative", zIndex: 2 }}>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="page-heading text-white">
              <h1>{title}</h1>
            </div>
            <div className="breadcrumb-wrap">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {currentPage}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
