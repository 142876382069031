import React from "react";
import icon1 from "../assets/img/icons/t1.png";
import icon2 from "../assets/img/icons/t2.png";

const Timeline = () => {
  return (
    <section className="company-timeline-wrapper section-padding">
      <div className="container">
        <div className="row mt-lg-5 mt-3">
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-trust"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Global </span> Opportunities
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-cashier-machine"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Secure</span> Registered by Govt
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-goal"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Mission</span> For Achivement
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
