import { Link } from "react-router-dom";
import mainLogo from "../../assets/img/logo.png";

const OffsetMenu = ({ offset, handleOffset }) => {
  return (
    <>
      <div className={offset ? "offset-menu" : "offset-menu show"}>
        <span onClick={handleOffset} id="offset-menu-close-btn">
          <i className="fal fa-plus"></i>
        </span>
        <div className="offset-menu-wrapper text-white">
          <div className="offset-menu-header">
            <div className="offset-menu-logo">
              <Link to="/homeOne">
                <img
                  style={{
                    width: "100%",

                    height: "100px",
                    objectFit: "cover",
                  }}
                  src="/images/logo-white.png"
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className="offset-menu-section">
            <h3>About Us</h3>
            <p>
              Taste of Life is a pioneering company specializing in electrical
              and automation solutions, dedicated to enhancing the quality of
              life through innovative technology.
            </p>
            <Link to="/contact" className="theme-btn  mt-30">
              Consultation
            </Link>
          </div>
          <div className="offset-menu-section">
            <h3>Contact Info</h3>
            <ul>
              <li>
                <span>
                  <i className="fal fa-map"></i>
                </span>
              North Karachi,Karachi,Pakistan
              </li>
              <li>
                <span>
                  <i className="fal fa-phone"></i>
                </span>
               03332398142
              </li>
              <li>
                <span>
                  <i className="fal fa-envelope"></i>
                </span>
               hr@taste-oflife.com
              </li>
              <li>
                <span>
                  <i className="fal fa-clock"></i>
                </span>
                Week Days: 09 AM to 06 PM
              </li>
              <li>
                <span>
                  <i className="fal fa-clock"></i>
                </span>
                Saturday and Sunday: Closed
              </li>
            </ul>
          </div>
          <div className="offset-menu-footer">
            <div className="offset-menu-social-icon">
             <a href="https://www.facebook.com/profile.php?id=100067875286974">
                <i className="fab fa-facebook-f"></i>
              </a>

              <a href="https://www.youtube.com/@eatelectricalautomationtra1069">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffsetMenu;
